<template>
	<div>
		<el-dialog title="统计" :visible.sync="show" :close-on-click-modal="false" width="1000px" top="100px"
			@close="closePhone">
			<div style="text-align: right;">
				
				<el-radio-group v-model="radio">
				    <el-radio label="all">全部</el-radio>
				    <el-radio label="yes">只看已签名</el-radio>
				    <el-radio label="no">只看未签名</el-radio>
				  </el-radio-group>
				<el-button size="mini" v-print="'#dtable'" style="margin-left: 20px;">打印</el-button>
			</div>
			<table id="dtable" class="dtable" v-if="DataInfo">
				<tr>
					<th colspan="5" style="font-size: 20px;">
						{{DataInfo.title}}({{DataInfo.confirme_count+DataInfo.stu_ok}}/{{DataInfo.total}})</th>
				</tr>
				<tr>
					<th>发布人：{{DataInfo.username}}</th>
					<th>发布时间：{{DataInfo.cdate}}</th>
					<th>是否需要确认：{{DataInfo.need_confirm?'是':'否'}}</th>
					<th>是否需要签字：{{DataInfo.need_sign_pic?'是':'否'}}</th>
					<th>是否需要拍照：{{DataInfo.need_zipai?'是':'否'}}</th>
				</tr>
				<tr>
					<th colspan="5">教职工</th>
					
				
				</tr>
				<tr>
					<th>所在部门</th>
					<th>姓名</th>
					<th>阅读时间</th>
					<th>签字</th>
					<th>拍照</th>

				</tr>
				<tr v-for="(tch,idx) in DataInfo.teachers " :key="idx" v-if="radio=='all'">
					<td>{{tch.depts.map(e=>{return e.dept_name}).join(",")}}</td>
					<td>{{tch.username}}</td>
					<td>{{tch.read_time}}</td>
					<td>
						<el-image v-if="tch.hand_sign" 
						  style="width: 60px; "
						  :src="tch.hand_sign+'?x-oss-process=image/resize,w_200'"
						  :preview-src-list="[tch.hand_sign]"
						  :z-index='99999'
						>
						</el-image>
						
					</td>
					<td>
					<el-image v-if="tch.cam_pic" 
					  style="width: 60px; "
					  :src="tch.cam_pic+'?x-oss-process=image/resize,w_200'"
					  :preview-src-list="[tch.cam_pic]"
					  :z-index='99999'
					>
					</el-image>
					</td>


				</tr>
				<tr v-for="(tch,idx) in DataInfo.teachers " :key="idx" v-if="radio=='yes'&&tch.read_time">
					<td>{{tch.depts.map(e=>{return e.dept_name}).join(",")}}</td>
					<td>{{tch.username}}</td>
					<td>{{tch.read_time}}</td>
					<td>
						<el-image v-if="tch.hand_sign" 
						  style="width: 60px; "
						  :src="tch.hand_sign+'?x-oss-process=image/resize,w_200'"
						  :preview-src-list="[tch.hand_sign]"
						  :z-index='99999'
						>
						</el-image>
						
					</td>
					<td>
					<el-image v-if="tch.cam_pic" 
					  style="width: 60px; "
					  :src="tch.cam_pic+'?x-oss-process=image/resize,w_200'"
					  :preview-src-list="[tch.cam_pic]"
					  :z-index='99999'
					>
					</el-image>
					</td>
				
				
				</tr>
				<tr v-for="(tch,idx) in DataInfo.teachers " :key="idx" v-if="radio=='no'&&!tch.read_time">
					<td>{{tch.depts.map(e=>{return e.dept_name}).join(",")}}</td>
					<td>{{tch.username}}</td>
					<td>{{tch.read_time}}</td>
					<td>
						<el-image v-if="tch.hand_sign" 
						  style="width: 60px; "
						  :src="tch.hand_sign+'?x-oss-process=image/resize,w_200'"
						  :preview-src-list="[tch.hand_sign]"
						  :z-index='99999'
						>
						</el-image>
						
					</td>
					<td>
					<el-image v-if="tch.cam_pic" 
					  style="width: 60px; "
					  :src="tch.cam_pic+'?x-oss-process=image/resize,w_200'"
					  :preview-src-list="[tch.cam_pic]"
					  :z-index='99999'
					>
					</el-image>
					</td>
				
				
				</tr>
				<tr>
					<th colspan="5">学生信息</th>
					
				
				</tr>
				<tr>
					<th>所在班级</th>
					<th>姓名</th>
					<th>阅读时间</th>
					<th>签字</th>
					<th>拍照</th>
				
				</tr>
				
				<tr v-for="(tch,idx) in DataInfo.students " :key="'stu_'+idx" v-if="radio=='all'">
					<td>{{tch.cls?tch.cls.grade_name+tch.cls.class_name:''}}</td>
					<td>{{tch.stu_name}}</td>
					<td>{{tch.read_time}}</td>
					<td>
						<el-image v-if="tch.hand_sign" 
						  style="width: 60px; "
						  :src="tch.hand_sign+'?x-oss-process=image/resize,w_200'"
						  :preview-src-list="[tch.hand_sign]"
						  :z-index='99999'
						>
						</el-image>
						
					</td>
					<td>
					<el-image v-if="tch.cam_pic" 
					  style="width: 60px; "
					  :src="tch.cam_pic+'?x-oss-process=image/resize,w_200'"
					  :preview-src-list="[tch.cam_pic]"
					  :z-index='99999'
					>
					</el-image>
					</td>
				
				
				</tr>
				<tr v-for="(tch,idx) in DataInfo.students " :key="'stu_'+idx" v-if="radio=='yes'&&tch.read_time">
					<td>{{tch.cls?tch.cls.grade_name+tch.cls.class_name:''}}</td>
					<td>{{tch.stu_name}}</td>
					<td>{{tch.read_time}}</td>
					<td>
						<el-image v-if="tch.hand_sign" 
						  style="width: 60px; "
						  :src="tch.hand_sign+'?x-oss-process=image/resize,w_200'"
						  :preview-src-list="[tch.hand_sign]"
						  :z-index='99999'
						>
						</el-image>
						
					</td>
					<td>
					<el-image v-if="tch.cam_pic" 
					  style="width: 60px; "
					  :src="tch.cam_pic+'?x-oss-process=image/resize,w_200'"
					  :preview-src-list="[tch.cam_pic]"
					  :z-index='99999'
					>
					</el-image>
					</td>
				
				
				</tr>
				<tr v-for="(tch,idx) in DataInfo.students " :key="'stu_'+idx" v-if="radio=='no'&&!tch.read_time">
					<td>{{tch.cls?tch.cls.grade_name+tch.cls.class_name:''}}</td>
					<td>{{tch.stu_name}}</td>
					<td>{{tch.read_time}}</td>
					<td>
						<el-image v-if="tch.hand_sign" 
						  style="width: 60px; "
						  :src="tch.hand_sign+'?x-oss-process=image/resize,w_200'"
						  :preview-src-list="[tch.hand_sign]"
						  :z-index='99999'
						>
						</el-image>
						
					</td>
					<td>
					<el-image v-if="tch.cam_pic" 
					  style="width: 60px; "
					  :src="tch.cam_pic+'?x-oss-process=image/resize,w_200'"
					  :preview-src-list="[tch.cam_pic]"
					  :z-index='99999'
					>
					</el-image>
					</td>
				
				
				</tr>


			</table>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: {
			id: ""
		},
		data() {
			return {
				
				
				radio:"all",
				show: true,
				DataInfo: null,
				colCount: 3
			}
		},
		mounted() {
			this.getData()
		},
		methods: {
			getData() {
				this.$http.post("/api/notify_read_info", {
					id: this.id
				}).then(res => {
					// for (let t of res.data.teachers) {
					// 	if (t.cam_pic) {
					// 		if (t.cam_pic.indexOf("?") > 0) {
					// 			t.cam_pic += "&x-oss-process=image/resize,w_200"
					// 		} else {
					// 			t.cam_pic += "?x-oss-process=image/resize,w_200"
					// 		}
					// 	}
					// }
					// for (let t of res.data.students) {
					// 	if (t.cam_pic) {
					// 		if (t.cam_pic.indexOf("?") > 0) {
					// 			t.cam_pic += "&x-oss-process=image/resize,w_200"
					// 		} else {
					// 			t.cam_pic += "?x-oss-process=image/resize,w_200"
					// 		}
					// 	}
					// }
					this.DataInfo = res.data

				})
			},
			closePhone() {
				this.$parent.closePhone()
			}
		}
	}
</script>

<style>
</style>
